import { createApp, defineAsyncComponent } from "vue";

// async loading of component
const vuePasswordStrengthIndicatorElement: HTMLElement | null = document.querySelector(
  ".js-vue--password-strength-indicator",
);
if (vuePasswordStrengthIndicatorElement) {
  // pass the data attributes to the component
  console.debug("Must mount PasswordStrengthIndicator", { ...vuePasswordStrengthIndicatorElement.dataset });
  const component = defineAsyncComponent(() => import("./PasswordStrengthIndicator.vue"));
  createApp(component, { ...vuePasswordStrengthIndicatorElement.dataset }).mount(vuePasswordStrengthIndicatorElement);
}

// async loading of component
const vueCnkSelectElements: NodeListOf<HTMLElement> = document.querySelectorAll(".js-vue--cnk-select");
if (vueCnkSelectElements) {
  vueCnkSelectElements.forEach((vueCnkSelectElement: HTMLElement) => {
    // pass the data attributes to the component
    console.debug("Must mount CnkSelectElement", { ...vueCnkSelectElement.dataset });
    const component = defineAsyncComponent(() => import("./CnkSelector.vue"));
    createApp(component, { ...vueCnkSelectElement.dataset }).mount(vueCnkSelectElement);
  });

  // async loading of component
  const vueMultiselectElements: NodeListOf<HTMLElement> = document.querySelectorAll(".js-vue--multiselect");
  if (vueMultiselectElements) {
    vueMultiselectElements.forEach((vueMultiselectElement: HTMLElement) => {
      // pass the data attributes to the component
      console.log("Must mount MultiselectElement", { ...vueMultiselectElement.dataset });
      const component = defineAsyncComponent(() => import("./MultiSelector.vue"));
      createApp(component, { ...vueMultiselectElement.dataset }).mount(vueMultiselectElement);
    });
  }

  // async loading of component
  const vueMainIngredientSelectElements: NodeListOf<HTMLElement> = document.querySelectorAll(
    ".js-vue--mainingredientselect",
  );
  if (vueMainIngredientSelectElements) {
    vueMainIngredientSelectElements.forEach((mainIngredientSelectElement: HTMLElement) => {
      // pass the data attributes to the component
      console.log("Must mount MainIngredientSelector", { ...mainIngredientSelectElement.dataset });
      const component = defineAsyncComponent(() => import("./MainIngredientSelector.vue"));
      createApp(component, { ...mainIngredientSelectElement.dataset }).mount(mainIngredientSelectElement);
    });
  }
}
