// entry point for general site code / not for vue code
declare var Multilang: any;

import "./components/mount-components";

function SetupPasswordMatchValidation() {
  const password = document.getElementById("NewPassword") as HTMLInputElement | null,
    confirm_password = document.getElementById("ConfirmPassword") as HTMLInputElement | null;

  if (password == null || confirm_password == null) {
    return;
  }

  function validatePassword() {
    if (password == null || confirm_password == null) {
      return;
    }

    if (password.value != confirm_password.value) {
      confirm_password.setCustomValidity(Multilang?.PasswordCompareNoMatch || "Passwords Don't Match");
    } else {
      confirm_password.setCustomValidity("");
    }
  }

  password.onchange = validatePassword;
  confirm_password.onkeyup = validatePassword;
}

SetupPasswordMatchValidation();

console.log("🚀 index.ts loaded");
